<template>
  <section :class="`menuRecords`">
    <div :class="['menuRecords__header', isMobileAndOpen && 'menuRecords__header--openMobile']" v-if="isMobile">
      <span class="menuRecords__appTitle">My Space 1A</span>
      <iconic class="menuRecords__close" name="times" @click.native="changeMenu('hidden')" />
    </div>
    <div :class="['menuRecords__wrapper', !isMobile && 'menuRecords__wrapper--withPadding']">
      <div
        :class="`menuRecords__floatButton menuRecords__floatButton--${menuState}`"
        @click="changeMenu(menuState === 'long' ? 'short' : 'long')"
        v-if="!isMobile"
      >
        <iconic name="arrow_right" />
      </div>
      <div class="menuRecords__userAndData">
        <div class="menuRecords__contentAvatar">
          <Avatar :class="['menuRecords__avatar', isShort && 'menuRecords__avatar--mobile']" :user="user" />
          <span :class="['menuRecords__state', isShort && 'menuRecords__state--mobile']"></span>
        </div>
        <div class="menuRecords__info">
          <h2 class="menuRecords__name">{{ user.artisticName || user.name }}</h2>
          <p class="menuRecords__role">{{ user.role }}</p>
          <h4 class="menuRecords__city">{{ `${user.city}${user.office ? ` - ${user.office}` : ""}` }}</h4>
        </div>
      </div>
      <nav class="menuRecords__contentNav">
        <MenuCard v-for="(menu, idx) in menus" :menu="menu" :state="menuState" :key="idx" />
      </nav>
    </div>
  </section>
</template>

<script>
export default {
  components: {
    Avatar: () => import("@/common/Avatar.vue"),
    MenuCard: () => import("./MenuCard.vue"),
  },
  computed: {
    user() {
      return this.$store.state.userProfile;
    },
    menuState() {
      return this.$store.state.menuState;
    },
    isShort() {
      return this.menuState === "short";
    },
    isMobile() {
      return this.$screen.width < 1024;
    },
    isMobileAndOpen() {
      return this.isMobile && this.menuState === "long";
    },
    menus() {
      return this.$store.getters.myMenus;
    },
  },
  watch: {
    isMobile(val) {
      this.changeMenu(val ? "hidden" : "short");
    },
  },
  methods: {
    changeMenu(state) {
      this.$store.commit("changeMenuState", state);
    },
  },
  mounted() {
    if (this.isMobile) {
      this.changeMenu("hidden");
    }
  },
};
</script>

<style lang="scss">
.menuRecords {
  height: 100%;
  background-color: $white;
  box-shadow: 0 2px 5px $alto;
  &__header {
    @include Flex(row, space-between);
    height: 64px;
    box-shadow: 0 2px 5px $alto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: 0.3s ease-in-out;
    &--openMobile {
      padding: $mpadding;
    }
  }
  &__appTitle {
    font-family: $third_font;
    font-size: 17px;
    font-weight: bold;
    color: $primary-color;
  }
  &__close {
    padding: 5px;
    color: $primary-color;
    cursor: pointer;
  }
  &__wrapper {
    position: relative;
    padding: $mpadding;
    &--withPadding {
      padding-top: 79px;
    }
  }
  &__floatButton {
    @include Flex(row);
    position: absolute;
    top: 80px;
    right: -10px;
    z-index: 5;
    width: $mpadding * 1.5;
    height: $mpadding * 1.5;
    font-size: 12px;
    color: $primary_color;
    background-color: #fff;
    border: solid 1px;
    border-radius: 50px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    &--long {
      rotate: 180deg;
      color: #fff;
      background-color: $primary_color;
    }
  }
  &__userAndData {
    @include Flex(column);
    gap: 10px;
    width: 100%;
    padding-bottom: $mpadding;
    border-bottom: 1px solid $primary_color;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &__contentAvatar {
    position: relative;
    max-width: 105px;
  }
  &__avatar {
    height: 105px;
    width: 105px;
    font-size: 18px;
    border: 3px solid $primary_color;
    transition: 0.3s ease-in-out;
    &--mobile {
      height: 52px;
      width: 52px;
    }
  }
  &__state {
    position: absolute;
    right: $mpadding/4;
    bottom: $mpadding/4;
    height: $mpadding * 1.5;
    width: $mpadding * 1.5;
    background-color: #209e0c;
    border-radius: 100%;
    border: solid 2px #fff;
    transition: 0.3s ease-in-out;
    &--mobile {
      right: 0;
      bottom: 0;
      height: $mpadding;
      width: $mpadding;
    }
  }
  &__contentNav,
  &__info {
    @include Flex(column);
    gap: 5px;
    width: 100%;
  }
  &__contentNav {
    gap: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &__name {
    max-width: 100%;
    font-size: 1rem;
  }
  &__role {
    max-width: 100%;
    text-transform: capitalize;
    font-size: 0.9rem;
    color: #adaeae;
  }
  &__city {
    max-width: 100%;
    padding: $mpadding/4 $mpadding/2;
    text-transform: uppercase;
    font-size: 0.7rem;
    color: #fff;
    background-color: #000;
    border-radius: $mradius/2;
  }
  &__contentNav {
    padding-top: $mpadding;
  }
}
</style>
